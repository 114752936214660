import React from 'react';
import './style.sass';
import './script.js';

import ReactGA from 'react-ga'

import thumbsup from '../../img/thumbs-up.svg'
import thumbsdown from '../../img/thumbs-down.svg'



class Feedback extends React.Component {
    // function to handle the click
    thumbsUp() {
        ReactGA.event({
            category: 'feedback',
            action: 'positive'
        })
        document.getElementById("feedback-thumbs").classList.add("collapsed");
        document.getElementById("feedback-thanks").classList.remove("collapsed");
    }

    thumbsDown() {
        ReactGA.event({
            category: 'feedback',
            action: 'negative'
        })
        document.getElementById("feedback-thumbs").classList.add("collapsed");
        document.getElementById("feedback-comment").classList.remove("collapsed");
    }

    comment() {
        ReactGA.event({
            category: 'feedback',
            action: 'comment',
            label: document.getElementById("feedback-comment-text").value
        })
        document.getElementById("feedback-comment").classList.add("collapsed");
        document.getElementById("feedback-thanks").classList.remove("collapsed");
    }

    render() {
        return (
            <div id="feedback">
                <div id="feedback-thumbs">
                    <h3>Wat vond je van dit artikel?</h3>
                    <img id="thumbs-down" src={thumbsdown} alt="Duimpje omlaag" onClick={this.thumbsDown} />
                    <img id="thumbs-up" src={thumbsup} alt="Duimpje omhoog" onClick={this.thumbsUp} />
                </div>
                <div id="feedback-thanks" className="collapsed">
                    <h3>Dankjewel voor je feedback!</h3>
                    <p><span role="img" aria-label="smile">😀</span></p>
                </div>
                <div id="feedback-comment" className="collapsed">
                    <h3>Hoe kan ik je beter helpen?</h3>
                    <textarea name="feedback-comment-text" id="feedback-comment-text" cols="10" rows="3"></textarea>
                    <button className="button" onClick={this.comment}>Verstuur feedback</button>
                </div>
            </div>
        );
    }
}

export default Feedback;
